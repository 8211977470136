import React from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'

import BottomBarToaster from '../../components/BottomBarToaster'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { redirectRoute } from '../../shared/utils/redirect'
import Page from '../Page'
import { customerRoutesData } from './customerRoutesData'
import { CustomerRoutesEnum } from './customerRoutesEnum'
import { unauthenticatedRoutesData } from './unauthenticatedRoutesData'
import { useGetAdminRoutesData } from './adminRoutesData'

function Routes(): JSX.Element {
	const { customer, token, carts } = useTypedSelector([
		'customer',
		'token',
		'carts'
	])
	const { adminRoutesData } = useGetAdminRoutesData()
	const isLogged = !!customer && !!token

	const currentRoute = window.location.pathname
	const isDeactivatedRoute = Object.values(CustomerRoutesEnum).includes(currentRoute as CustomerRoutesEnum);

	if(isDeactivatedRoute) {
		window.location.pathname = '/login'
		localStorage.removeItem('@loggedUserData')
	}

	const authenticatedRoutesData = isLogged
		? (customer.Type === 'CUSTOMER'
				? customer.CustomerID
					? customerRoutesData
					: customerRoutesData.filter((x) => x.customerPending)
				: adminRoutesData
		  ).filter((route) => route.accessTypes.includes(customer.Type))
		: []

	const routesData = [
		...authenticatedRoutesData,
		...unauthenticatedRoutesData
	]

	if (
		customer.Type === 'CUSTOMER' &&
		carts?.map((cart) => cart?.transaction)[0]
	) {
		const props = customerRoutesData.find(
			(route) => route.path === CustomerRoutesEnum.PIX_QR_CODE
		)

		if (!props) return <></>
		return (
			<>
				{/*@ts-ignore*/}
				<BrowserRouter>
					{/*@ts-ignore*/}
					<Switch>
						<Page {...props} />

						{/*@ts-ignore*/}
						<Redirect
							from={'*'}
							to={CustomerRoutesEnum.PIX_QR_CODE}
						/>
					</Switch>
					{/*@ts-ignore*/}
					<BottomBarToaster />
				</BrowserRouter>
			</>
		)
	}

	return (
		<>
			{/*@ts-ignore*/}
			<BrowserRouter>
				{/*@ts-ignore*/}
				<Switch>
					{routesData.map(({ ...props }) => (
						<Page {...props} />
					))}

					{/*@ts-ignore*/}
					<Redirect
						from={'*'}
						to={redirectRoute(customer.Type, customer.CustomerID)}
					/>
				</Switch>
				{/*@ts-ignore*/}
				<BottomBarToaster />
			</BrowserRouter>
		</>
	)
}

export default Routes
