import { createElement } from 'react'

import {
	GPLogin,
} from '../index'

import { IRouteDataV2 } from './types'
import { UnauthenticatedRoutesEnum } from './unauthenticatedRoutesEnum'
import { RedirectToApp } from '../../views/redirect-to-app/redirect-to-app'

export const unauthenticatedRoutesData: IRouteDataV2[] = [
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.GP_LOGIN,
		path: UnauthenticatedRoutesEnum.GP_LOGIN,
		children: createElement(GPLogin)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.LOGIN,
		path: UnauthenticatedRoutesEnum.LOGIN,
		children: createElement(RedirectToApp)
	},
]
