import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import { 
  BomParceiroAppPresentation,
  BomParceiroAppPresentationContent,
  BomParceiroAppPresentationContentActions,
  BomParceiroAppPresentationContentDescription,
  BomParceiroAppQrCodeSection,
  BomParceiroDeactivatedPage,
  Footer,
  GrupoPetropolisBrands,
  HeaderWrapper,
  PrivacyCenter,
  SaberBeberAdvertisement,
  SacGpSection,
  UnderConstructionMessage 
} from './redirect-to-app.styles'
import  './styles/index.css'

import newLogoBomParceiro from './assets/bp-logo.svg'
import inConstructionFrame from './assets/construction-frame.svg'
import mobileApp from './assets/mobile-app.svg'
import googlePlayButton from './assets/google-button.svg'
import appleStoreButton from './assets/apple-button.svg'
import qrCodeApp from './assets/qrcode-app.svg'
import appAdvantages from './assets/app-advantages.svg'
import gpBrandsDesktop from './assets/gp-brands-desktop.svg'
import gpBrandsMobile from './assets/gp-brands-mobile.svg'
import sacGp from './assets/sac-gp.svg'

export const RedirectToAppView = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <BomParceiroDeactivatedPage>
      <HeaderWrapper>
        <img src={newLogoBomParceiro} alt="Bom parceiro logo"/>
      </HeaderWrapper>

      <UnderConstructionMessage>
        <img src={inConstructionFrame} alt="" className="fade-left"/>
        <p>Em construção</p>
        <img src={inConstructionFrame} alt="" className="fade-right"/>
      </UnderConstructionMessage>

      <BomParceiroAppPresentation>
        <BomParceiroAppPresentationContent>
          <BomParceiroAppPresentationContentDescription className="fade-up">
            <h1>
              BOM PARCEIRO o app do Grupo Petrópolis para o seu negócio
            </h1>
            <span>
              Mais do que um app, o parceiro ideal para manter seu estoque completo e ajudar você a vender mais. 
              Com o Bom Parceiro, você compra, acompanha seus pedidos e aproveita promoções de forma rápida, 
              prática e digital, tudo isso sem perder o foco no que realmente importa: seus clientes.
            </span>
            <BomParceiroAppPresentationContentActions>
              <a 
                href="https://play.google.com/store/apps/details?id=com.bomparceiro&pcampaignid=web_share" 
                target='_blank' 
                rel="noreferrer"
              >
                <img src={googlePlayButton} alt="Download Google Play"/>
              </a>
              <a
                href="https://apps.apple.com/br/app/bom-parceiro-gp/id1562870393" 
                target='_blank' 
                rel="noreferrer"
              >
                <img src={appleStoreButton} alt="Download Apple Store"/>
              </a>
            </BomParceiroAppPresentationContentActions>
 
          </BomParceiroAppPresentationContentDescription>
          {!isMobile && <img src={mobileApp} alt="Aplicativo mobile Bom Parceiro"  className="fade-up" />}
          
        </BomParceiroAppPresentationContent>
      </BomParceiroAppPresentation>

      <BomParceiroAppQrCodeSection>
        { isMobile 
          ? <img src={mobileApp} alt="Aplicativo mobile Bom Parceiro"  className="fade-up" /> 
          : <img src={qrCodeApp} alt="QR Code App Bom Parceiro" className="fade-up"/>
        }
        
        <img src={appAdvantages} alt="Vantagens APP Bom Parceiro"/>
      </BomParceiroAppQrCodeSection>

      <GrupoPetropolisBrands>
        <img src={isMobile ? gpBrandsMobile : gpBrandsDesktop} alt="Marcas Grupo Petropólis" className="fade-up" />
      </GrupoPetropolisBrands>

      <SacGpSection>
        <img src={sacGp} alt="Número Sac GP" className="fade-up"/>
      </SacGpSection>

      <Footer>
        <img src={newLogoBomParceiro} alt="Bom parceiro logo"/>
        <SaberBeberAdvertisement>Apoiamos o consumo responsável de bebidas alcoólicas por meio do programa Saber Beber.</SaberBeberAdvertisement>
        <PrivacyCenter>
          <a href="https://grupopetropolis303162.app.privacycenter.cloud" target="_blank" rel="noopener noreferrer">
            Central de Privacidade
          </a>
        </PrivacyCenter>

      </Footer>
    </BomParceiroDeactivatedPage>
  )
}