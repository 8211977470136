import { Box } from '@mui/material'
import styled from 'styled-components'
import bg from './assets/presentation-layer-background.svg'

export const BomParceiroDeactivatedPage = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  padding: 0;
  scroll-behavior: smooth;
`

export  const HeaderWrapper = styled(Box)`
  background: linear-gradient(92.58deg, #C3192C 8.21%, #C40F3A 26.52%, #C10D43 46.71%, #B70750 75.35%, #A70051 102.11%);
  width: 100%;
  height: 72px;

  img {
    display: absolute;
    height: 40px;
    width: 67px;
    margin-left: 156px;
    margin-top: 16px;
  }
`

export const UnderConstructionMessage = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #333333;
  width: 100%;
  height: 32px;
  padding: 8px;

  p {
    text-align: center;
    color: #ffffff;
  }


  @media (max-width: 768px) {
    flex-direction: row; 
    padding: 16px; 
    height: 35px;

    img {
      height: 32px;
      width: 30%;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
`

export const BomParceiroAppPresentation = styled(Box)`
  background-color: #feeaee;
  background-image: url(${bg});
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BomParceiroAppPresentationContent = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5rem;
`

export const BomParceiroAppPresentationContentDescription = styled(Box)`
  width: 30%;  
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    font-size: 35px;
    font-weight: bolder;
    color: #212121;
    line-height: 42px;
  }

  span {
    line-height:22px;
    font-size: 16px;
  }


  @media (max-width: 768px) {
    margin-top: 30px;

    h1 {
      font-size: 24px;
      line-height: 28px;
    }

    flex-direction: column; 
    height: auto; 
    padding: 16px; 
    width: 90%;  
    margin-bottom: 40px;
  }

`

export const BomParceiroAppPresentationContentActions = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;

  img {
    height: 40px;
    cursor: pointer;
  }
`

export const BomParceiroAppQrCodeSection = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -40px;

  @media (max-width: 768px) {
    flex-direction: column; 
    height: auto; 
    padding: 16px; 
  }
`

export const GrupoPetropolisBrands = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
  height: 300px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column; 
    height: auto; 
    padding: 16px; 
  }
`

export const SacGpSection = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;

  @media (max-width: 768px) {
    height: auto; 
    padding: 16px; 
  }
`

export const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 40px;
  height: 96px;
  background: linear-gradient(92.58deg, #C3192C 8.21%, #C40F3A 26.52%, #C10D43 46.71%, #B70750 75.35%, #A70051 102.11%);

  img {
    height: 64px;
  }
`

export const PrivacyCenter = styled(Box)`
  cursor: pointer;

  a {
    color: white;
    text-decoration: none;
    cursor: inherit;
  }

  p {
    cursor: inherit;
  }
`;

export const SaberBeberAdvertisement = styled.p`
  color: white;
`